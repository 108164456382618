<p>
  Welcome to Aqua Server WebUI<br>
  This version work with Aqua server v0.0.13
</p>

<mat-card>
  <mat-card-title>Data Info</mat-card-title>
  <mat-card-content>
    Diva Music List : {{divaPv}}<br>
    Diva Module List : {{divaPv}}<br>
    Diva Customize List : {{divaPv}}<br>
    Chunithm Music List : {{chuniMusic}}<br>
    Chunithm Character List : {{chuniCharacter}}<br>
    Chunithm Skill List : {{chuniSkill}}<br>
    Ongeki Card List : {{ongekiCard}}<br>
    Ongeki Character List : {{ongekiCharacter}}<br>
    Ongeki Music List : {{ongekiMusic}}<br>
    Ongeki Skill List : {{ongekiSkill}}
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="reload()">Clean Cache</button>
  </mat-card-actions>
</mat-card>
