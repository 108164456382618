<div *ngIf="playLogList">
  <mat-card *ngFor="let item of playLogList" routerLink="/diva/record/{{item.pvId}}">
    <mat-card-title>{{item.dateTime}}</mat-card-title>
    <mat-card-content>
      <div class="song-header">
        <div class="song-info">
          <span class="title">{{item.songInfo != null ? item.songInfo.songName : 'pvId:' + item.pvId}}</span><br>
          <span
            class="info">{{item.songInfo != null ? 'Lyric: ' + item.songInfo.lyrics + ' Song: ' + item.songInfo.music : ''}}</span>
        </div>
        <div class="level">
          {{item.edition === 1 ? edition[item.edition] : ''}}
          {{difficulty[item.difficulty]}}
        </div>
      </div>
      <div class="result-content">
        <div class="left">
          Rank:<br>
          <span class="rank">{{result[item.clearResult]}}</span>
          <br>
          <br>Achievement:<br>
          <span class="achievement-value">{{item.attainPoint|divaDecimal}}%</span>
          <br>
          <br>Score:<br>
          <span class="score-value">{{item.score}}</span><br><br>
          <table *ngIf="item.modulesInfo">
            <tr>
              <th>Module</th>
            </tr>
            <tr>
              <td *ngIf="item.modulesInfo[0]">
                <span class="module1">{{item.modulesInfo[0].name}}</span>
              </td>
            </tr>
            <tr>
              <td *ngIf="item.modulesInfo[1]">
                <span class="module1">{{item.modulesInfo[1].name}}</span>
              </td>
            </tr>
            <tr>
              <td *ngIf="item.modulesInfo[2]">
                <span class="module1">{{item.modulesInfo[2].name}}</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="right">
          <table class="zebra result-table">
            <tr>
              <th>Cool</th>
              <td>{{item.coolCount}}</td>
              <td>{{item.coolPercent|divaDecimal}}%</td>
            </tr>
            <tr>
              <th>Fine</th>
              <td>{{item.fineCount}}</td>
              <td>{{item.finePercent|divaDecimal}}%</td>
            </tr>
            <tr>
              <th>Safe</th>
              <td>{{item.safeCount}}</td>
              <td>{{item.safePercent|divaDecimal}}%</td>
            </tr>
            <tr>
              <th>Sad</th>
              <td>{{item.sadCount}}</td>
              <td>{{item.sadPercent|divaDecimal}}%</td>
            </tr>
            <tr>
              <th class="result-head">Worst / Wrong</th>
              <td>{{item.wrongCount}}</td>
              <td>{{item.wrongPercent|divaDecimal}}%</td>
            </tr>
            <tr>
              <th>Combo</th>
              <td colspan="2">{{item.maxCombo}}</td>
            </tr>
            <tr>
              <th class="result-head">Challenge Time</th>
              <td colspan="2">{{item.chanceTime}}</td>
            </tr>
            <tr>
              <th>Hold</th>
              <td colspan="2">{{item.holdScore}}</td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <button (click)="load()" mat-flat-button>Load More</button>

</div>
