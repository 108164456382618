<mat-card>
  <mat-card-title>ONGEKI Settings</mat-card-title>
</mat-card>

<mat-card>
  <mat-card-title>Export data</mat-card-title>
  <mat-card-content>
    <div class="action">
      <a href="{{apiServer + '/api/game/ongeki/export?aimeId=' + aimeId}}" mat-button target="_blank">Download</a>
    </div>
  </mat-card-content>
</mat-card>
