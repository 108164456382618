<h1 mat-dialog-title>Change Se</h1>
<div mat-dialog-content>
  <span>Set to -1 if you want to disable</span>
  <mat-form-field>
    <mat-label>Button Se</mat-label>
    <input [(ngModel)]="data.buttonSe" matInput type="number">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Chain Slide Se</mat-label>
    <input [(ngModel)]="data.chainSlideSe" matInput type="number">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Slide Se</mat-label>
    <input [(ngModel)]="data.slideSe" matInput type="number">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Slider Touch Se</mat-label>
    <input [(ngModel)]="data.sliderTouchSe" matInput type="number">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>No Thanks</button>
  <button [mat-dialog-close]="data" cdkFocusInitial mat-button>Ok</button>
</div>
