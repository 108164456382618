import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-diva-management',
  templateUrl: './diva-management.component.html',
  styleUrls: ['./diva-management.component.css']
})
export class DivaManagementComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
