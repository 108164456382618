<h1 mat-dialog-title>Set Rival</h1>
<div mat-dialog-content>
  <p>Ask your friend's Project DIVA ID or add rival at ranking page</p>
  <mat-form-field>
    <mat-label>Rival PdId:</mat-label>
    <input type="number" [(ngModel)]="data.rivalId" matInput>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>No Thanks</button>
  <button [mat-dialog-close]="data" cdkFocusInitial mat-button>Ok</button>
</div>
