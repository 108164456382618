<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>
<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="pvId">
    <th mat-header-cell *matHeaderCellDef> PvId.</th>
    <td mat-cell *matCellDef="let element"> {{element.pvId}} </td>
  </ng-container>
  <ng-container matColumnDef="songName">
    <th mat-header-cell *matHeaderCellDef> Name</th>
    <td mat-cell *matCellDef="let element"> {{element.songName}} </td>
  </ng-container>
  <ng-container matColumnDef="songNameEng">
    <th mat-header-cell *matHeaderCellDef> Eng. Name</th>
    <td mat-cell *matCellDef="let element"> {{element.songNameEng}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/diva/record/{{row.pvId}}"></tr>

</table>
<mat-paginator [pageSizeOptions]="[15, 50, 100]"></mat-paginator>
