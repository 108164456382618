<h2>Management</h2>
<mat-nav-list>
  <mat-list-item routerLink="/diva/management/festa">
    <a matLine>Festa</a>
  </mat-list-item>
  <mat-list-item routerLink="/diva/management/contest">
    <a matLine>Contest</a>
  </mat-list-item>
  <mat-list-item routerLink="/diva/management/news">
    <a matLine>News / Warning</a>
  </mat-list-item>
</mat-nav-list>
