<mat-card>
  <mat-card-title>Player Profile</mat-card-title>
  <mat-card-content>
    <table *ngIf="profile" class="zebra">
      <tr>
        <th>Project DIVA ID</th>
        <td>{{profile.pdId}}</td>
      </tr>
      <tr>
        <th>Player Name</th>
        <td>{{profile.playerName}}</td>
      </tr>
      <tr>
        <th>Player Level</th>
        <td>{{profile.level}}</td>
      </tr>
      <tr>
        <th>Level Exp</th>
        <td>{{profile.levelExp}}%</td>
      </tr>
      <tr>
        <th>Player Title</th>
        <td>{{profile.levelTitle}}</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
