<mat-card>
  <mat-card-title>My Card List</mat-card-title>
  <mat-card-content>
    <p class="hint">
      Hint: <br>
      Every card has base Lv.10 Max. After Kaika, max level will plus 40. (Means 10 + 40)<br>
      Every Star larger than 1 will add 5 levels to max level. And R,SR,SSR card can get max 5 stars, N card can get max
      10 stars.<br>
      Means max level for N card is 100, for R,SR,SSR card is 70.<br>
      Star level can be increase by getting the same card or use item in game.
    </p>
    <div class="action">
      <button mat-button routerLink="all">Show All Cards</button>
      <button mat-button routerLink="gacha">Gacha(Work in progress)</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card
  *ngFor="let item of cardList | async | paginate: { id: 'server', itemsPerPage: 10, currentPage: currentPage, totalItems: totalElements }">
  <mat-card-title>
    {{item.cardInfo ? item.cardInfo.name : 'ID:' + item.cardId}}
  </mat-card-title>
  <mat-card-content>
    <div class="card-display">
      <div class="image">
        <a href="{{host}}ongeki/card/UI_Card_{{item.cardId|formatNumber:6}}.png" target="_blank">
          <img src="{{host}}ongeki/card/UI_Card_{{item.cardId|formatNumber:6}}_S.png">
        </a>
        <div *ngIf="item.characterInfo">
          {{item.characterInfo.name}}<br>
          {{item.characterInfo.cv ? '(CV: ' + item.characterInfo.cv + ')' : ''}}
        </div>
      </div>
      <div class="content">
        <table class="zebra">
          <tr>
            <th>Level</th>
            <td>{{item.level}}</td>
          </tr>
          <tr>
            <th>Current Max Level</th>
            <td>{{item.maxLevel}}</td>
          </tr>
          <tr>
            <th>Star</th>
            <td>{{item.digitalStock}}</td>
          </tr>
          <tr>
            <th>Skill</th>
            <td>{{item.skillInfo ? item.skillInfo.name : 'ID:' + item.skillId}}</td>
          </tr>
          <tr>
            <th>Kaika</th>
            <td>{{item.kaikaDate == '0000-00-00 00:00:00.0' ? 'No' : 'Yes'}}</td>
          </tr>
          <tr>
            <th>ChoKaika</th>
            <td>{{item.choKaikaDate == '0000-00-00 00:00:00.0' ? 'No' : 'Yes'}}</td>
          </tr>
        </table>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div fxFlex></div>
    <button (click)="kaika(item.cardId, 'kaika')" [disabled]="item.kaikaDate != '0000-00-00 00:00:00.0'"
            mat-button>
      Kaika
    </button>
    <button (click)="kaika(item.cardId, 'choKaika')" [disabled]="item.choKaikaDate != '0000-00-00 00:00:00.0'"
            mat-button>
      ChoKaika
    </button>
  </mat-card-actions>
</mat-card>
<div class="pagination">
  <pagination-controls (pageChange)="load($event)" id="server"></pagination-controls>
</div>
