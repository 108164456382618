<h1 mat-dialog-title>Change MyList</h1>
<div mat-dialog-content>
  <span>Length is 40, so make sure there is 39 comma</span><br>
  <mat-form-field>
    <mat-label>myList</mat-label>
    <mat-select [(ngModel)]="data.selector">
      <mat-option value="0">myList1</mat-option>
      <mat-option value="1">myList2</mat-option>
      <mat-option value="2">myList3</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="data.selector === '0'">
    <mat-label>myList1</mat-label>
    <textarea [(ngModel)]="data.myList0" matInput></textarea>
  </mat-form-field>
  <mat-form-field *ngIf="data.selector === '1'">
    <mat-label>myList2</mat-label>
    <textarea [(ngModel)]="data.myList1" matInput></textarea>
  </mat-form-field>
  <mat-form-field *ngIf="data.selector === '2'">
    <mat-label>myList3</mat-label>
    <textarea [(ngModel)]="data.myList2" matInput></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>No Thanks</button>
  <button [mat-dialog-close]="data" cdkFocusInitial mat-button>Ok</button>
</div>
