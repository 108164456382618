<div>
  <mat-card>
    <mat-card-content>
      <div class="song-info">
        <img *ngIf="music" src="{{host}}chuni/jacket/CHU_UI_Jacket_{{music.musicId|formatNumber:4}}.jpg">
        <div class="text-content">
          <span class="title">{{music != null ? music.name : 'musicId:' + id}}</span><br>
          <span
            class="info">{{music != null ? 'Artist: ' + music.artistName : ''}}</span><br>
          <span
            class="info">{{music != null ? 'Genre: ' + music.genre : ''}}</span><br>
          <span
            class="info">{{music != null ? 'Release Version: ' + music.releaseVersion : ''}}</span>
        </div>

      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngFor="let item of levels">
    <mat-card-title>
      <div class="level-title">
        <div class="difficulty">
          {{difficulty[item.diff]}}
        </div>
        <div class="level">
          {{'Level: ' + item.level + '.' + item.levelDecimal}}
        </div>
      </div>
    </mat-card-title>
    <ng-container *ngIf="records[item.diff] as record; else no_record">
      <mat-card-content>
        <div class="result-content">
          <div class="left">
            Max Rank:<br>
            <span class="achievement-value">{{record.scoreRank|toRank}}</span>
            <br>
            <br>Max Score:<br>
            <span class="score-value">{{record.scoreMax}}</span>
            <br>
          </div>
          <table class="zebra">
            <tr>
              <th>Is AJ?</th>
              <td>{{record.isAllJustice}}</td>
              <td>Play Count</td>
              <td>{{record.playCount}}</td>
            </tr>
            <tr>
              <th>Is FC?</th>
              <td>{{record.isFullCombo}}</td>
              <td>Max Combo</td>
              <td>{{record.maxComboCount}}</td>
            </tr>
            <tr>
              <th>Is Clear?</th>
              <td>{{record.isSuccess}}</td>
              <td>Miss Count</td>
              <td>{{record.missCount}}</td>
            </tr>
          </table>
        </div>
        <mat-card-actions>
          <button mat-flat-button routerLink="{{item.diff}}">Show all play history</button>
        </mat-card-actions>
      </mat-card-content>
    </ng-container>
    <ng-template #no_record>
      <mat-card-content>
        No play record
      </mat-card-content>
    </ng-template>
  </mat-card>

</div>
