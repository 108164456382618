<div *ngIf="customizes">
  <mat-card *ngFor="let item of customizes | paginate: {itemsPerPage: 15, currentPage: p}">
    <mat-card-content>
      <span>Module ID: {{item.id}}</span><br><br>
      <span>Name: {{item.name}}</span><br>
      <span>Price: {{item.price}} VP</span>
    </mat-card-content>
  </mat-card>
</div>
<pagination-controls class="pagination" maxSize="9"
                      (pageChange)="p = $event"
                      directionLinks="true"
                      autoHide="true"
                      responsive="true"
                      previousLabel="Previous"
                      nextLabel="Next"
                      screenReaderPaginationLabel="Pagination"
                      screenReaderPageLabel="page"
                      screenReaderCurrentLabel="You're on page">
</pagination-controls>
