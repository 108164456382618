<mat-card class="login-card">
  <mat-card-title>
    Login
  </mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">

      <mat-form-field class="full-width">
        <mat-label>Access Code</mat-label>
        <input formControlName="accessCode" matInput required type="text">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>ApiServer</mat-label>
        <input formControlName="apiServer" matInput required type="text">
      </mat-form-field>
      <button [disabled]="!loginForm.valid" mat-raised-button type="submit">Login</button>
    </form>
  </mat-card-content>
</mat-card>
