<mat-card>
  <mat-card-title>
    <div>Contest List, Length: {{contests ? contests.length : 0}}</div>
    <button (click)="edit(undefined)" mat-flat-button>Add</button>
  </mat-card-title>
</mat-card>
<div *ngIf="contests">
  <mat-card *ngFor="let contest of contests">
    <mat-card-content>
      <table class="zebra">
        <tr>
          <th>ID</th>
          <td>{{contest.id}}</td>
        </tr>
        <tr>
          <th>Enabled</th>
          <td>{{contest.enable}}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{{contest.name}}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{{contest.description}}</td>
        </tr>
        <tr>
          <th>Start Time</th>
          <td>{{contest.startTime}}</td>
        </tr>
        <tr>
          <th>End Time</th>
          <td>{{contest.endTime}}</td>
        </tr>
        <tr>
          <th>League</th>
          <td>{{contestLeague[contest.league]}}</td>
        </tr>
        <tr>
          <th>Star</th>
          <td>{{contest.stars}}</td>
        </tr>
        <tr>
          <th>Min Complexity</th>
          <td>{{contest.minComplexity}}</td>
        </tr>
        <tr>
          <th>Max Complexity</th>
          <td>{{contest.maxComplexity}}</td>
        </tr>
        <tr>
          <th>Stages</th>
          <td>{{contest.stages}}</td>
        </tr>
        <tr>
          <th>Stage Limit</th>
          <td>{{contestStageLimit[contest.stageLimit]}}</td>
        </tr>
        <tr>
          <th>Norma Type</th>
          <td>{{contestNormaType[contest.normaType]}}</td>
        </tr>
        <tr>
          <th>Bronze borders</th>
          <td>{{contest.bronzeBorders}}</td>
        </tr>
        <tr>
          <th>Sliver borders</th>
          <td>{{contest.sliverBorders}}</td>
        </tr>
        <tr>
          <th>Gold borders</th>
          <td>{{contest.goldBorders}}</td>
        </tr>
        <tr>
          <th>Pv List</th>
          <td>{{contest.pvList}}</td>
        </tr>
        <tr>
          <th>Pv Difficulty List</th>
          <td>{{contest.pvDiffList}}</td>
        </tr>
        <tr>
          <th>Bronze Contest Reward</th>
          <td>{{contest.bronzeContestReward}}</td>
        </tr>
        <tr>
          <th>Silver Contest Reward</th>
          <td>{{contest.sliverContestReward}}</td>
        </tr>
        <tr>
          <th>Gold Contest Reward</th>
          <td>{{contest.goldContestReward}}</td>
        </tr>
        <tr>
          <th>Contest Entry Reward</th>
          <td>{{contest.contestEntryReward}}</td>
        </tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="edit(contest)" mat-flat-button>Edit</button>
      <button (click)="delete(contest.id)" mat-flat-button>Delete</button>
    </mat-card-actions>
  </mat-card>
</div>
