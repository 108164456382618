<mat-card>
  <mat-card-title>
    <div class="title">News</div>
  </mat-card-title>
  <mat-card-content *ngIf="newsForm">
    <form (ngSubmit)="submitNews()" [formGroup]="newsForm">
      <mat-form-field class="full-width">
        <mat-label>Content</mat-label>
        <textarea formControlName="propertyValue" matInput required type="text"></textarea>
      </mat-form-field>
      <button [disabled]="!newsForm.valid" mat-flat-button type="submit">Submit</button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>
    <div class="title">Warning</div>
  </mat-card-title>
  <mat-card-content *ngIf="warningForm">
    <form (ngSubmit)="submitWarning()" [formGroup]="warningForm">
      <mat-form-field class="full-width">
        <mat-label>Content</mat-label>
        <textarea formControlName="propertyValue" matInput required type="text"></textarea>
      </mat-form-field>
      <button [disabled]="!warningForm.valid" mat-flat-button type="submit">Submit</button>
    </form>
  </mat-card-content>
</mat-card>
