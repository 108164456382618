<mat-form-field>
  <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter">
</mat-form-field>
<table [dataSource]="dataSource" mat-table matSort>
  <ng-container matColumnDef="id">
    <th *matHeaderCellDef mat-header-cell> Id.</th>
    <td *matCellDef="let element" mat-cell> {{element.id}} </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell> Name</th>
    <td *matCellDef="let element" mat-cell> {{element.name}} </td>
  </ng-container>
  <ng-container matColumnDef="artistName">
    <th *matHeaderCellDef mat-header-cell> Artist</th>
    <td *matCellDef="let element" mat-cell> {{element.artistName}} </td>
  </ng-container>
  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns;" mat-row routerLink="/ongeki/song/{{row.musicId}}"></tr>

</table>
<mat-paginator [pageSizeOptions]="[15, 50, 100]"></mat-paginator>
