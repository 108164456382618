<h1 mat-dialog-title>Change Player Name</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input [(ngModel)]="data.playerName" matInput>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>No Thanks</button>
  <button [mat-dialog-close]="data.playerName" cdkFocusInitial mat-button>Ok</button>
</div>
