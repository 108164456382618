<div *ngIf="pvRecords">
  <mat-card *ngFor="let item of pvRecords" routerLink="{{item.pvId}}">
    <mat-card-title>
      <div class="title">{{item.songInfo != null ? item.songInfo.songName : 'pvId:' + item.pvId}}</div>
      <div class="level">
        {{item.edition === 1 ? edition[item.edition] : ''}}
        {{difficulty[item.difficulty]}}
      </div>
    </mat-card-title>
    <mat-card-content>
      <div class="song-info">
        <span
          class="info">{{item.songInfo != null ? 'Lyric: ' + item.songInfo.lyrics + ' Song: ' + item.songInfo.music : ''}}</span>
      </div>
      <div class="result-content">
        <div class="left">
          <br>
          <span class="rank-value">{{result[item.result]}}</span>
        </div>
        <div class="left">
          Achievement:<br>
          <span class="achievement-value">{{item.maxAttain|divaDecimal}}%</span>
        </div>
        <div class="left">
          Score:<br>
          <span class="score-value">{{item.maxScore}}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <button (click)="load()" mat-flat-button>Load More</button>

</div>
