<mat-card>
  Please notice that malformed input will break profile loading<br>
  Leave it empty if you don't know the correct format for some string value
</mat-card>

<mat-card *ngIf="profile">
  <mat-card-title>Player Name</mat-card-title>
  <mat-card-content>
    <div class="content">{{profile.playerName}}</div>
    <div class="action">
      <button (click)="playerName()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="profile">
  <mat-card-title>Player Title</mat-card-title>
  <mat-card-content>
    <div class="content">{{profile.levelTitle}}</div>
    <div class="action">
      <button (click)="playerTitle()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="profile">
  <mat-card-title>Player Plate</mat-card-title>
  <mat-card-content>
    <div class="content">
      Plate ID: {{profile.plateId}}<br>
      Plate Effect ID: {{profile.plateEffectId}}
    </div>
    <div class="action">
      <button (click)="playerPlate()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="profile">
  <mat-card-title>Common Skin</mat-card-title>
  <mat-card-content>
    <div class="content">
      Skin ID:
      {{profile.commonSkin}}
    </div>
    <div class="action">
      <button (click)="skin()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="profile">
  <mat-card-title>MyList</mat-card-title>
  <mat-card-content>
    <div class="content">
      MyList 1:<br>
      {{profile.myList0}}<br>
      MyList 2:<br>
      {{profile.myList1}}<br>
      MyList 3:<br>
      {{profile.myList2}}
    </div>
    <div class="action">
      <button (click)="myList()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="profile">
  <mat-card-title>Common Se</mat-card-title>
  <mat-card-content>
    <div class="content">
      Button Se ID: {{profile.buttonSe == -1 ? 'Disabled' : profile.buttonSe}}<br>
      Chain Slide Se ID: {{profile.chainSlideSe == -1 ? 'Disabled' : profile.chainSlideSe}}<br>
      Slide Se ID: {{profile.slideSe == -1 ? 'Disabled' : profile.slideSe}}<br>
      Slider Touch Se ID: {{profile.sliderTouchSe == -1 ? 'Disabled' : profile.sliderTouchSe}}<br>
    </div>
    <div class="action">
      <button (click)="commonSe()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="profile">
  <mat-card-title>Display Setting</mat-card-title>
  <mat-card-content>
    <div class="content">
      Show Ranking: {{profile.showInterimRanking}}<br>
      Show Clear Status: {{profile.showClearStatus}}<br>
      Show Great Border: {{profile.showGreatBorder}}<br>
      Show Rival Border: {{profile.showRivalBorder}}<br>
      Show Rhythm Game Option: {{profile.showRgoSetting}}<br>
    </div>
    <div class="action">
      <button (click)="display()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="rival">
  <mat-card-title>Rival</mat-card-title>
  <mat-card-content>
    <div class="content">
      Rival Name: {{rival}}<br>
    </div>
    <div class="action">
      <button (click)="setRival()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>
