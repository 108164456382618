<mat-card>
  <mat-card-title>CHUNITHM Settings</mat-card-title>
</mat-card>

<mat-card>
  <mat-card-title>Username</mat-card-title>
  <mat-card-content *ngIf="profile">
    <div class="content">{{profile.userName}}</div>
    <div class="action">
      <button (click)="userName()" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>Privacy</mat-card-title>
  <mat-card-content *ngIf="profile">
    <div class="content">Hide Rating Info:</div>
    <div class="action">
      <button (click)="null" mat-button>Edit</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>Export data</mat-card-title>
  <mat-card-content>
    <div class="action">
      <a href="{{apiServer + '/api/game/chuni/amazon/export?aimeId=' + aimeId}}" mat-button target="_blank">Download</a>
    </div>
  </mat-card-content>
</mat-card>
