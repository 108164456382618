<mat-card>
  <mat-card-title>Battle Point</mat-card-title>
</mat-card>

<mat-card>
  <mat-card-content>
    Current Battle Point: {{profile ? profile.battlePoint : ''}}
  </mat-card-content>
</mat-card>

<mat-card>
  <h3>Best Battle Points</h3>
  <table class="zebra battle-point-table">
    <thead>
    <th>Music</th>
    <th>Level</th>
    <th>Point</th>
    <th>Attr.</th>
    </thead>
    <tbody>
    <tr *ngFor="let item of bPList; index as i">
      <td class="music-title">
        <img src="{{host}}ongeki/jacket/UI_Jacket_{{item.musicId|formatNumber:4}}.png">
        {{i + 1}}: {{item.musicInfo ? item.musicInfo.name : 'MusicID:' + item.musicId}}
      </td>
      <td>
        {{difficulty[item.level]}}
      </td>
      <td>
        {{item.value}}
      </td>
      <td>
        {{item.bossCardInfo ? item.bossCardInfo.attribute : 'Unknown' }}
      </td>
    </tr>
    </tbody>
  </table>
</mat-card>
