<mat-card>
  <mat-card-title>
    <div class="title">Contest ID: {{f.id.value}}</div>
  </mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="onSubmit()" [formGroup]="contestForm">
      <mat-form-field class="full-width">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput required type="text">
      </mat-form-field>

      <mat-checkbox class="full-width" formControlName="enable">Enabled</mat-checkbox>

      <mat-form-field class="full-width">
        <mat-label>Description</mat-label>
        <textarea formControlName="description" matInput required type="text"></textarea>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Start Time</mat-label>
        <input [matDatepicker]="startTime" formControlName="startTime" matInput placeholder="Choose a start date">
        <mat-datepicker-toggle [for]="startTime" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startTime></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>End Time</mat-label>
        <input [matDatepicker]="endTime" formControlName="endTime" matInput placeholder="Choose a end date">
        <mat-datepicker-toggle [for]="endTime" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #endTime></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>League</mat-label>
        <mat-select formControlName="league">
          <mat-option value="0">Beginner</mat-option>
          <mat-option value="1">Intermediate</mat-option>
          <mat-option value="2">Advanced</mat-option>
          <mat-option value="3">Professional</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Stars</mat-label>
        <input formControlName="stars" matInput required type="number">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>minComplexity</mat-label>
        <input formControlName="minComplexity" matInput required type="number">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>maxComplexity</mat-label>
        <input formControlName="maxComplexity" matInput required type="number">
      </mat-form-field>
      <p>Complexity: Chart Level(Stars) × 2. eg: 9.5 starts is 19. max:20, min:1. </p>

      <mat-form-field class="full-width">
        <mat-label>Stages</mat-label>
        <input formControlName="stages" matInput required type="number">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Stage Limit</mat-label>
        <mat-select formControlName="stageLimit">
          <mat-option value="0">Unlimited</mat-option>
          <mat-option value="1">Limited</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Norma Type</mat-label>
        <mat-select formControlName="normaType">
          <mat-option value="0">Score</mat-option>
          <mat-option value="1">Percentage</mat-option>
          <mat-option value="2">Cool Percentage</mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field class="full-width">
        <mat-label>bronzeBorders</mat-label>
        <input formControlName="bronzeBorders" matInput required type="number">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>sliverBorders</mat-label>
        <input formControlName="sliverBorders" matInput required type="number">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>goldBorders</mat-label>
        <input formControlName="goldBorders" matInput required type="number">
      </mat-form-field>

      <p>Please set the value below carefully, or it will break contest loading and stage saving. Leave it empty if you
        doesn't know what that means.</p>

      <mat-form-field class="full-width">
        <mat-label>Pv List</mat-label>
        <input formControlName="pvList" matInput placeholder="pv_id_start:pv_id_end..." type="text">
      </mat-form-field>
      <p>Pv List Format: "pv_id_start:pv_id_end,pv_id_start:pv_id_end,pv_id_start:pv_id_end". <br>
        Max allowed length is 20. Use -1 as range end if you only need a single PV.</p>

      <mat-form-field class="full-width">
        <mat-label>Pv Difficulty List</mat-label>
        <input formControlName="pvDiffList" matInput type="text"
               placeholder="pv_difficulty:min_complexity:max_complexity...">
      </mat-form-field>
      <p>Pv Difficulty List Format: "pv_difficulty:min_complexity:max_complexity,...". Use it to control the difficulty
        of Pv List. <br>
        pv_difficulty: -1 is unrestricted, 0 is easy, 1 is normal. But it seems not work with 2 and 3 so leave it -1 and
        set the complexity. <br>
        complexity: format same as above. </p>
      <mat-form-field class="full-width">
        <mat-label>Bronze Contest Reward</mat-label>
        <input formControlName="bronzeContestReward" matInput type="text" placeholder="rewardType:rewardId:string1:string2">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Silver Contest Reward</mat-label>
        <input formControlName="sliverContestReward" matInput type="text" placeholder="rewardType:rewardId:string1:string2">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Gold Contest Reward</mat-label>
        <input formControlName="goldContestReward" matInput type="text" placeholder="rewardType:rewardId:string1:string2">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Contest Entry Reward</mat-label>
        <input formControlName="contestEntryReward" matInput type="text">
      </mat-form-field>

      <p>Contest Reward Format: "rewardType:rewardId:string1:string2" string1 and 2 should be urlencoded and must exist. use *** aka %2A%2A%2A as placeholder <br>
        Reward Type: (-1 None, 0 VP, 1 Skin, 2 Callsign, 3 Customize)</p>
      <button [disabled]="!contestForm.valid" mat-flat-button type="submit">Submit</button>
    </form>
  </mat-card-content>
</mat-card>
