<mat-card>
  <mat-card-title>
    <div class="title">Festa</div>
  </mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="onSubmit()" [formGroup]="festaForm">
      <mat-form-field class="full-width">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput required type="text">
      </mat-form-field>
      <mat-checkbox class="full-width" formControlName="enable">Enabled</mat-checkbox>
      <mat-form-field class="full-width">
        <mat-label>Kind</mat-label>
        <mat-select formControlName="kind">
          <mat-option value="0">PinkFesta</mat-option>
          <mat-option value="1">GreenFesta</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Difficulty Limit</mat-label>
        <mat-select formControlName="difficulty">
          <mat-option value="-1">Unset</mat-option>
          <mat-option value="0">Easy</mat-option>
          <mat-option value="1">Normal</mat-option>
          <mat-option value="2">Hard</mat-option>
          <mat-option value="3">Extreme</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>PV List</mat-label>
        <input formControlName="pvList" matInput required type="text">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Attributes</mat-label>
        <input formControlName="attributes" matInput required type="text">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Add VP</mat-label>
        <input formControlName="addVP" matInput required type="number">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>VP Multiplier</mat-label>
        <input formControlName="vpMultiplier" matInput required type="number">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Start Time</mat-label>
        <input [matDatepicker]="start" formControlName="start" matInput placeholder="Choose a start date">
        <mat-datepicker-toggle [for]="start" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #start></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>End Time</mat-label>
        <input [matDatepicker]="end" formControlName="end" matInput placeholder="Choose a end date">
        <mat-datepicker-toggle [for]="end" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #end></mat-datepicker>
      </mat-form-field>
      <button [disabled]="!festaForm.valid" mat-flat-button type="submit">Submit</button>
    </form>
  </mat-card-content>
</mat-card>
