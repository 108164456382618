<div>
  <mat-card>
    <h3>Total Rating <span
      class="{{(topTotal + recentTotal) / 40 | toRating | ratingClass}}">{{(topTotal + recentTotal) / 40 | toRating }}</span>
    </h3>
    <h3>Top <span class="{{topTotal / 30 | toRating | ratingClass}}">{{topTotal / 30 | toRating }}</span></h3>
    <h3>Recent <span class="{{recentTotal / 10 | toRating | ratingClass}}">{{recentTotal / 10 | toRating }}</span></h3>
  </mat-card>
</div>
<div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">

  <div class="skill-card" fxFlex="45">
    <mat-card>
      <h3>Top</h3>
      <table class="zebra">
        <thead>
        <th>Music</th>
        <th>Diff</th>
        <th>Score</th>
        <th>Rating</th>
        </thead>
        <tbody>
        <tr *ngFor="let item of topRating; index as i">
          <td>
            <img src="{{host}}chuni/jacket/CHU_UI_Jacket_{{item.musicId|formatNumber:4}}.jpg">
            {{i + 1}}: {{item.musicName}}
          </td>
          <td>
            {{item.ratingBase|toRating}}
          </td>
          <td>
            {{item.score}}
          </td>
          <td>
            <span class="{{item.rating|toRating|ratingClass}}">{{item.rating|toRating}}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-card>
  </div>
  <div fxFlex="10">
  </div>
  <div class="skill-card" fxFlex="45">
    <mat-card>
      <h3>Recent</h3>
      <table class="zebra">
        <thead>
        <th>Music</th>
        <th>Diff</th>
        <th>Score</th>
        <th>Rating</th>
        </thead>
        <tbody>
        <tr *ngFor="let item of recentRating; index as i">
          <td>
            <img src="{{host}}chuni/jacket/CHU_UI_Jacket_{{item.musicId|formatNumber:4}}.jpg">
            {{i + 1}}: {{item.musicName}}
          </td>
          <td>
            {{item.ratingBase|toRating}}
          </td>
          <td>
            {{item.score}}
          </td>
          <td>
            <span class="{{item.rating|toRating|ratingClass}}">{{item.rating|toRating}}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-card>
  </div>
</div>
