<mat-card>
  <mat-card-title>
    <div>Festa List, Length: {{festas ? festas.length : 0}}</div>
    <button (click)="edit(undefined)" mat-flat-button>Add</button>
  </mat-card-title>
</mat-card>
<div *ngIf="festas">
  <mat-card *ngFor="let festa of festas">
    <mat-card-content>
      <table class="zebra">
        <tr>
          <th>ID</th>
          <td>{{festa.id}}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{{festa.name}}</td>
        </tr>
        <tr>
          <th>FestaKind</th>
          <td>{{festaKind[festa.kind]}}</td>
        </tr>
        <tr>
          <th>Difficulty</th>
          <td>{{difficulty[festa.difficulty]}}</td>
        </tr>
        <tr>
          <th>PvList</th>
          <td>{{festa.pvList}}</td>
        </tr>
        <tr>
          <th>Attributes</th>
          <td>{{festa.attributes}}</td>
        </tr>
        <tr>
          <th>Add VP</th>
          <td>{{festa.addVP}}</td>
        </tr>
        <tr>
          <th>VP Multiplier</th>
          <td>{{festa.vpMultiplier}}</td>
        </tr>
        <tr>
          <th>Start</th>
          <td>{{festa.start}}</td>
        </tr>
        <tr>
          <th>End</th>
          <td>{{festa.end}}</td>
        </tr>
        <tr>
          <th>Create Date</th>
          <td>{{festa.createDate}}</td>
        </tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="edit(festa)" mat-flat-button>Edit</button>
      <button (click)="delete(festa.id)" mat-flat-button>Delete</button>
    </mat-card-actions>
  </mat-card>
</div>
